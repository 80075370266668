import React, { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
  GReactMD,
  PageOrUrl,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import {
  getLiveSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";
import Title from "../components/bloks/title";
import { LanguageContext } from "../components/context/language-context";
import MinicardLive from "../components/layout/minicard-live";
import { FormLogicWrapper } from "../components/utils/forms";

const Live = ({ data, pageContext, location }) => {
  const { t, locale } = useContext(LanguageContext);
  const story = data?.cont;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }
  const sharedContent = JSON.parse(data?.sharedContent?.content ?? "{}");
  const allLives = data.allLives.nodes.map((n) =>
    JSON.parse(n?.content ?? "{}")
  );

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  const { name, color, image, description, text, youtube_id } = story.content;

  const searchParams = new URLSearchParams(location?.search ?? "");
  const showLive = searchParams.get("show-live") === "true";

  return (
    <Layout lm={pageContext.lm}>
      <Title
        b={{
          title: name,
          style: "halfpage",
          img: image,
          bg_color: color,
          cta: t.watch_video,
          page_or_url: "#watch-video",
        }}
      />
      <div id="watch-video" />
      {youtube_id ? (
        showLive ? (
          <div className="w-full">
            {text && (
              <div className="mud-cont-text py-6">
                <GReactMD className="blog-p">{text}</GReactMD>
              </div>
            )}
            {youtube_id && (
              <iframe
                title={name ?? "Tutorial MUD"}
                className="mud-cont w-full lg:h-[40vw] h-[50vw] mt-8"
                src={`https://www.youtube.com/embed/${youtube_id}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            )}
          </div>
        ) : (
          <div className="flex flex-col lg:mud-grid-2-1 lg:border-b-[1px] lg:border-black">
            <div className="flex flex-col lg:grid lg:grid-cols-2 lg:max-w-screen-lg lg:mr-0 lg:ml-auto lg:pt-16 lg:pl-16 lg:gap-10 gap-4 p-5">
              <div>
                <GReactMD>{description}</GReactMD>
              </div>
              <div className="flex flex-col gap-5 font-bold">
                {(sharedContent?.shared_body_bloks?.[0].elements ?? []).map(
                  (e, i) => {
                    i++;
                    return (
                      <li key={e._uid} className="flexc gap-5">
                        <span className="bg-white shrink-0 rounded-full w-8 h-8 flexc justify-center border-[1px] border-black">
                          {i}
                        </span>
                        {e?.value}
                      </li>
                    );
                  }
                )}
              </div>
            </div>
            <div className="bg-mud-yellow">
              <div className="max-w-lg mr-auto ml-0 px-5 py-7">
                <FormLogicWrapper
                  cta={t.watch_video}
                  type="academy"
                  style="solid"
                  extra={{ liveName: name }}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="flex flex-col lg:mud-grid-2-1 lg:border-b-[1px] lg:border-black">
          <div className="flex flex-col lg:grid lg:grid-cols-2 lg:max-w-screen-lg lg:mr-0 lg:ml-auto lg:pt-16 lg:pl-16 lg:gap-10 gap-4 p-5">
            <div className="flex flex-col gap-5">
              <p>{t.error_video_live_1}</p>
              <p>{t.error_video_live_2}</p>
            </div>
          </div>
          <div className="bg-mud-yellow">
            <div className="max-w-lg mr-auto ml-0 px-5 py-7">
              <FormLogicWrapper
                cta={t.cta_go}
                type="prenota-call"
                style="solid"
                extra={{ liveName: name }}
              />
            </div>
          </div>
        </div>
      )}

      <div className="mud-sottotitolo text-center mt-10 mb-8">
        <h3>{t.recommended}</h3>
      </div>
      {(allLives ?? [])
        .filter((e) => e.name !== name)
        .map((e, i) => {
          while (i < 4) return <MinicardLive b={e} key={e._uid + i} />;
        })}
      <section className="text-center justify-center">
        <PageOrUrl
          slug={(location?.pathname ?? "").replace(
            story?.content?.page_information?.[0]?.slug,
            "/"
          )}
        >
          <div className="p-8 font-bold uppercase hover:underline">
            {t.more_live}
          </div>
        </PageOrUrl>
      </section>
      {(sharedContent?.shared_footer_bloks ?? []).map(getBlok)}
    </Layout>
  );
};

export const Head = ({ data, pageContext }) => {
  const story = data.cont;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getLiveSlug(seoInfo.slug, story.lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");
  const lm = { ...(pageContext?.lm ?? {}) };
  for (const k in lm) {
    lm[k] = prefixUrl(lm[k]);
  }

  return <Seo info={seoInfo} lm={lm} />;
};

export const query = graphql`
  query ($id: String!, $images: [String]!, $lang: String!) {
    cont: storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(filter: { base: { in: $images } }) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    allLives: allStoryblokEntry(
      filter: { field_component: { eq: "Registrazione" }, lang: { eq: $lang } }
    ) {
      nodes {
        content
      }
    }
    sharedContent: storyblokEntry(
      slug: { eq: "live-academy-shared-content" }
      lang: { eq: $lang }
    ) {
      content
    }
  }
`;

export default Live;
